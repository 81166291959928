import React, { useEffect, useState, useRef } from 'react'
import { createThread, sendMessage } from './apigpt'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CopyAll from '@mui/icons-material/CopyAll'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import '../index.css'

/** 
 * "VAR-prosedyrer" - en blå lenke uten ramme.
 * Ved klikk åpnes iFrame under.
 */
const VarProcedyrerLink = ({ href, children }) => {
  const [open, setOpen] = useState(false)
  const toggleIframe = () => setOpen(!open)
  return (
    <span className="var-prosedyre-container">
      <button className="var-prosedyre-link" onClick={toggleIframe}>
        <ExpandMoreIcon style={{ fontSize: '16px' }} />
        {children}
      </button>
      {open && (
        <iframe
          src={href}
          className="var-prosedyre-iframe"
          title="VAR-prosedyrer"
        />
      )}
    </span>
  )
}

/**
 * Parser markdown-linjer slik at:
 *  - H2 (## ...) havner i 'h2Blocks' (én felles Markdown-blokk, ingen checkboks)
 *  - H3 (### ...) blir egne "seksjoner" med checkbox
 */
function parseMarkdownByH2AndH3(markdownText) {
  const lines = markdownText.split('\n')
  const h2Blocks = []   
  const h3Sections = []

  let currentH3 = null

  lines.forEach((line) => {
    if (line.trim().startsWith('### ')) {
      // Ny h3-seksjon
      if (currentH3) {
        h3Sections.push(currentH3)
      }
      currentH3 = {
        headingLine: line, // "### Trykksår"
        contentLines: []
      }
    } else {
      // Vanlig linje (f.eks. H2, brødtekst, tom)
      if (currentH3) {
        // hører til pågående H3
        currentH3.contentLines.push(line)
      } else {
        // H2, vanlige linjer
        h2Blocks.push(line)
      }
    }
  })

  // Siste h3
  if (currentH3) {
    h3Sections.push(currentH3)
  }

  return { h2Blocks, h3Sections }
}

const ChatMessage = ({ message }) => {
  const [checkedSections, setCheckedSections] = useState({})

  if (!message?.msg) return null

  // Del i H2-blokk vs. H3-seksjoner
  const { h2Blocks, h3Sections } = parseMarkdownByH2AndH3(message.msg)

  // Overstyr lenker -> iFrame-løsning
  const customComponents = {
    a: ({ node, children, ...props }) => {
      if (props.href?.includes('demo.semanticpulse.no')) {
        return <VarProcedyrerLink href={props.href}>{children}</VarProcedyrerLink>
      }
      return <a {...props}>{children}</a>
    }
  }

  // Oppdater checkboks state
  const handleCheckboxChange = (index, h3Block, checked) => {
    setCheckedSections((prev) => {
      const newState = { ...prev }
      if (checked) {
        // Filtrer ut linjer som eventuelt begynner med "## "
        const combined = [h3Block.headingLine, ...h3Block.contentLines]
          .filter(line => !line.trim().startsWith('## '))
          .join('\n')
        newState[index] = combined
      } else {
        delete newState[index]
      }
      return newState
    })
  }
  

  const handleCopySelection = () => {
    const selected = Object.values(checkedSections)
    if (!selected.length) return
    navigator.clipboard.writeText(selected.join('\n\n'))
  }

  const handleCopyAll = () => {
    navigator.clipboard.writeText(message.msg || '')
  }

  return (
    <div className={message.isBot ? 'bot-message' : 'user-message'}>
      {/* Rendrer H2-linjer (f.eks. ## PROBLEM) uten checkbokser */}
      {h2Blocks.length > 0 && (
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={customComponents}
        >
          {h2Blocks.join('\n')}
        </Markdown>
      )}

      {/* Rendrer H3-linjer (f.eks. ### Trykksår) med checkboks. */}
      {h3Sections.map((h3Block, idx) => {
        const isChecked = !!checkedSections[idx]
        // Fjern '### ' fra headingLine:
        const headingText = h3Block.headingLine.replace(/^###\s*/, '')
        const contentMarkdown = h3Block.contentLines.join('\n')

        return (
          <div key={idx} className="h3-section" style={{ margin: '1em 0' }}>
            {/* 1) "label" inneholder checkbox + h3 i én flex-rad */}
            <label style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginBottom: '0.3em' }}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(idx, h3Block, e.target.checked)}
              />
              <h3 style={{ margin: 0 }}>{headingText}</h3>
            </label>
            
            {/* 2) Beskrivelse + VAR-prosedyrer legges under, med litt innrykk */}
            {contentMarkdown.trim() && (
              <div style={{ marginLeft: '1.8em' }}>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={customComponents}
                >
                  {contentMarkdown}
                </Markdown>
              </div>
            )}
          </div>
        )
      })}


      {/* Kopierknapper for bot-melding */}
      {message.isBot && (
        <div style={{ marginTop: '0.5em' }} className="row gap">
          <button className="flat-button row align-center small-gap" onClick={handleCopySelection}>
            <span>Kopier utvalg</span>
            <CopyAll />
          </button>
          <button className="flat-button row align-center small-gap" onClick={handleCopyAll}>
            <span>Kopier alt</span>
            <CopyAll />
          </button>
        </div>
      )}
    </div>
  )
}

const KIChat = ({ term }) => {
  const [chat, setChat] = useState([])
  const [loading, setLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const endOfMessagesRef = useRef(null)
  const [userMessage, setUserMessage] = useState('')

  const handleStreamResponse = async (tid, messageContent) => {
    setLoading(true)
    try {
      const stream = await sendMessage(tid, messageContent)
      const reader = stream.getReader()
      let currentText = ''

      const read = async () => {
        const { done, value } = await reader.read()
        if (done) {
          setLoading(false)
          setIsGenerating(false)
          endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
          return
        } else {
          if (!isGenerating) setIsGenerating(true)
        }

        const chunk = new TextDecoder().decode(value)
        currentText += chunk
        setChat((prevChat) => {
          const lastMessage = prevChat[prevChat.length - 1]
          if (lastMessage && lastMessage.isBot) {
            return [...prevChat.slice(0, -1), { isBot: true, msg: currentText }]
          } else {
            return [...prevChat, { isBot: true, msg: currentText }]
          }
        })
        await read()
      }
      await read()
    } catch (error) {
      console.error('Error in streaming response:', error)
      setLoading(false)
      setIsGenerating(false)
    }
  }

  useEffect(() => {
    const initializeChat = async () => {
      if (term) {
        try {
          const newThreadId = await createThread()
          setThreadId(newThreadId)
          await handleStreamResponse(
            newThreadId,
            `Lag en pleieplan basert på dette snomedbegrepet ${term}`
          )
        } catch (error) {
          console.error('Failed to initialize chat:', error)
        }
      }
    }
    initializeChat()
  }, [term])

  useEffect(() => {
    if (!loading && isGenerating === false) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chat, loading, isGenerating])

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return
    setChat((prevChat) => [...prevChat, { isBot: false, msg: userMessage }])
    setUserMessage('')

    if (threadId) {
      await handleStreamResponse(threadId, userMessage)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className="ki-chat">
      <div className="chat-history column gap">
        {chat.map((message, index) => (
          <ChatMessage key={index} message={message} />
        ))}
        {loading && !isGenerating && (
          <div className="loader">
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
          </div>
        )}
        <div ref={endOfMessagesRef} />
      </div>

      {!isGenerating && !loading && (
        <div className="chat-input-container">
          <textarea
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Skriv din melding her..."
            className="chat-textarea"
          />
          <button
            onClick={handleSendMessage}
            className="send-button"
            disabled={isGenerating || loading || !userMessage.trim()}
          >
            Send
          </button>
        </div>
      )}
    </div>
  )
}

export default KIChat
